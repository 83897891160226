const services = {};

services.resolveEndpoint = () => {
  const ENDPOINT_URL_INDEX = 3;
  const urlParts = window.location.href.split('/');
  const endPoint = urlParts.length < ENDPOINT_URL_INDEX || !urlParts[ENDPOINT_URL_INDEX] || urlParts[ENDPOINT_URL_INDEX] === '#' ? 'default' : urlParts[ENDPOINT_URL_INDEX];
  return endPoint;
};

services.persistEndpoint = user => {
  return user.endPoint === services.resolveEndpoint();
};

export default services;
