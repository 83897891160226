const config = {
  connectToApi: true,
  color: 'selectedMenu',
  administrationColor: 'adminMenu',

  menuItems: [{
    id: 1,
    active: true,
    href: '/start',
    icon: 'home',
    name: 'inicio',
    onlyWsRM: true
  }, {
    id: 2,
    active: true,
    href: '/solicitacaoPage',
    icon: 'library_add',
    name: 'novaSolicitacao',
    onlyWsRM: true
  },{
    id: 3,
    active: true,
    href: '/requestList/solicitacoes',
    icon: 'list',
    name: 'minhasSolicitacoes',
    onlyWsRM: true
  }, {
    id: 4,
    active: true,
    href: '/requestList/participacoes',
    icon: 'fact_check',
    name: 'minhasParticipacoes',
    onlyWsRM: true
  }, {
    id: 5,
    active: true,
    href: '/aprovacoes',
    icon: 'rule',
    name: 'minhasAprovacoes',
    onlyWsRM: true
  }, {
    id: 6,
    active: true,
    href: '/organograma',
    icon: 'people',
    name: 'organograma',
    onlyWsRM: true
  }]
};

export default config;