import React from 'react';
import logo from '../../assets/img/logo-inter-dark.png';

const MenuLogo = () => {
  return (
    <div className="logo">
      <center>
        <img src={logo} alt="Guardian" width="190" height="30" />
      </center>
    </div>
  );
};

export default MenuLogo;
