import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from '../App';
import AsyncComponent from '../AsyncComponent';

const ParametroPage = () => import('./TelaParametro/ParametroPage');

const AdmRoutes = () => (
  <HashRouter>
    <Suspense fallback={<div>Carregando...</div>}>
      <App mode={'admin'}>
        <Routes>
          <Route exact path='/parametro' element={<AsyncComponent importComponent={ParametroPage} />} />
          <Route path='*' element={<Navigate to='/parametro' />} />
        </Routes>
      </App>
    </Suspense>
  </HashRouter>
);
export default AdmRoutes;
