import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../assets/locales/en/translations.json';
import translationPT from '../assets/locales/pt/translations.json';

const fallbackLng = ['pt'];
const availableLanguages = ['pt', 'en'];
const translatorService = {};

const resources = {
  en: {
    translation: translationEN,
    name: 'English'
  },
  pt: {
    translation: translationPT,
    name: 'Português'
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

translatorService.i18n = i18n;
translatorService.resources = resources;

export default translatorService;
