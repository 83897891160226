import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { createRoot } from 'react-dom/client';
import Start from './start';
import './services/translatorService';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css?v=1.2.6';
import './material-dashboard.min.css?v=1.2.6';
import '../node_modules/toastr/build/toastr.min.css';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Suspense fallback="...">
    <RecoilRoot>
      <RecoilNexus />
      <Start />
    </RecoilRoot>
  </Suspense>
);
