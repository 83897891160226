import React, { useState } from 'react';
import loginActions from '../../actions/loginActions';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import { sessionAtom } from '../../atoms/sessionAtom';
import { useTranslation } from 'react-i18next';
import Dglanguage from './language/DgLanguage';
import { useNavigate } from 'react-router-dom';
import './header.css';

const Header = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [session, setSession] = useRecoilState(sessionAtom);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();

  const toogleModal = show => {
    setShowModal(show);
  };

  const exit = () => {
    const newSession = {...session};
    newSession.requireValidation = false;
    newSession.logoff = true;
    setSession(newSession);
    loginActions.quit();
    navigation('/start/');
  };

  const toogleAdministration = enter => {
    const localUser = { ...user };
    localUser.accessingAdministrationMode = enter;
    setUser(localUser);
  };

  const accessMode = () => {
    if (!user.accessInfo.admin) return;
    let mode =
      <button className="text-blue forced-link header-action" onClick={() => toogleAdministration(false)}>
        <i className="material-icons">verified_user</i>
        <span className="forced-link-span">Guardian</span>
      </button>;
    if (!user.accessingAdministrationMode) {
      mode =
        <button className="text-blue forced-link header-action" onClick={() => toogleAdministration(true)}>
          <i className="material-icons">settings</i>
          <span className="forced-link-span">{t('administracao')}</span>
        </button>;
    }
    return mode;
  };

  const getContent = () => {
    const languageOption = i18n.language.substring(0, 2);
    const imgPath = `../assets/img/flags/icon-${languageOption}-48.png`;

    return (
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " id="navHeader">
        <div className="container-fluid">
          <Dglanguage show={showModal} onHide={() => toogleModal(false)} />
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <button className="text-blue forced-link header-action" onClick={() => toogleModal(true)}>
                  <img alt='flag' width={36}  src={`${imgPath}`}></img>
                </button>
              </li>
              {accessMode()}
              <li>
                <button className="text-blue forced-link header-action" to="/profile" >
                  <i className="material-icons">person</i>
                  <span className="forced-link-span">{user.userName}</span>
                </button>
              </li>
              <li>
                <button className="text-blue forced-link header-action" onClick={exit}>
                  <i className="material-icons">exit_to_app</i>
                  <span className="forced-link-span">{t('sair')}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  };

  return (getContent());
};

export default Header;
