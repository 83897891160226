import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';

const AsyncComponent = props => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (props.importComponent) {
      setComponent(lazy(props.importComponent));
    }
  }, [props.importComponent]);

  if (!Component) return (<div>Carregando...</div>);
  return (<Component />);
};

AsyncComponent.propTypes = {
  importComponent: PropTypes.func.isRequired,
  inputShowHeaderParams: PropTypes.bool
};

export default AsyncComponent;
