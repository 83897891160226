import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import messages from '../../commons/constMessages';
import { useRecoilState } from 'recoil';
import { sessionAtom } from '../../atoms/sessionAtom';
import apiUtils from '../../api/apiUtils';
import { PulseLoader } from 'react-spinners';
import './login.css';

const LoginSaml = props => {
  const [session, setSession] = useRecoilState(sessionAtom);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.errorMessage) {
      const newSession = { ...session };
      newSession.requireValidation = true;
      setSession(newSession);
    }
  }, [props.errorMessage]);

  useEffect(() => {
    if (props.user.client && !props.user.authError && !session.logoff) {
      initiate();
    }
  }, [props.user]);

  const initiate = async () => {
    setIsLoading(true);
    const { client } = props.user;
    const config = await apiUtils.getConfig();
    const samlToken = getQueryVariable('gso');
    if (samlToken) {
      const localUser = { ...props.user };
      localUser.userName = 'saml';
      localUser.password = samlToken;
      props.proceedLogin(localUser);
      return;
    }
    const err = getQueryVariable('err');
    if (err) {
      props.showErrorMessage(messages.ErrAuthSaml);
      setIsLoading(false);
      return;
    }
    const urlSso = `${client.ssoUrl}${config.appName}/${client.endpointName}/`;
    window.location.href = urlSso;
  };

  const getQueryVariable = variable => {
    var query = window.location.href.substring(window.location.href.indexOf('?') + 1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === variable) return pair[1];
    }
    return false;
  };

  const getComponent = () => {
    const component =
      <>
        {props.children}
        {getButton()}
      </>;
    return component;
  };

  const getButton = () => {
    if (isLoading) {
      return <center><PulseLoader size={10} color={'#333'} loading={true} /></center>;
    }
    const btn =
      <button className="btn btn-lg btn-success btn-block" onClick={handleSubmit}>
        Recarregar
      </button>;
    return btn;
  };

  const handleSubmit = e => {
    setIsLoading(true);
    e.preventDefault();

    const localUser = { ...props.user };
    localUser.authError = false;
    props.setUser(localUser);

    const newSession = { ...session };
    newSession.logoff = false;
    setSession(newSession);

    window.location.href = `${window.location.origin}/${localUser.client.endpointName}/`;
  };

  return (getComponent());
};

LoginSaml.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  proceedLogin: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

export default LoginSaml;
