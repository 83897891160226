import React from 'react';
import MenuLogo from './MenuLogo';
import MenuWrapper from './MenuWrapper';
import PropTypes from 'prop-types';

class Menu extends React.Component {
  render() {
    return (
      <div className="sidebar" >
        <MenuLogo />
        <MenuWrapper menuItems={this.props.items} color={this.props.color} />
      </div>
    );
  }
}

Menu.propTypes = {
  color: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default Menu;
