import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import AsyncComponent from './components/AsyncComponent';
import App from './components/App';

const StartPage = () => import('./components/start/StartPage');
const SolicitacaoPage = () => import('./components/solicitacaoNova/SolicitacaoPage');
const NovaVagaPage = () => import('./components/novaVaga/NovaVagaPage');
const desligamento = () => import('./components/desligamento/DesligamentoPage');
const movimentacao = () => import('./components/movimentacao/MovimentacaoPage');
const alteracaoEstrutura = () => import('./components/alteracaoEstrutura/AlteracaoEstruturaPage');
const novaSecaoPage = () => import('./components/alteracaoEstrutura/novaSecao/NovaSecaoPage');
const alteracaoSecaoPage = () => import('./components/alteracaoEstrutura/alteracaoSecao/AlteracaoSecaoPage');
const alocacaoColaboradorPage = () => import('./components/alteracaoEstrutura/alocacaoColaborador/AlocacaoColaboradorPage');
const inativarSecaoPage = () => import('./components/alteracaoEstrutura/inativarSecao/InativarSecaoPage');
const RequestListPage = () => import('./components/request/RequestListPage');
const ListaAprovacaoPage = () => import('./components/request/ListaAprovacaoPage');
const OrgPage = () => import('./components/organograma/OrgPage');

const AutRoutes = () => (
  <HashRouter>
    <Suspense fallback={<div>Carregando...</div>}>
      <App mode={'default'}>
        <Routes>
          <Route exact path='/start' element={<AsyncComponent importComponent={StartPage} />} />
          <Route exact path='/SolicitacaoPage' element={<AsyncComponent importComponent={SolicitacaoPage} />} />
          <Route exact path='/novaVaga' element={<AsyncComponent importComponent={NovaVagaPage} />} />
          <Route exact path='/novaVaga/codcoligada/:codColigada/idReq/:idReq/reqTipo/:reqTipo' element={<AsyncComponent importComponent={NovaVagaPage} />} />
          <Route exact path='/desligamento' element={<AsyncComponent importComponent={desligamento} />} />
          <Route exact path='/desligamento/codcoligada/:codColigada/idReq/:idReq/reqTipo/:reqTipo' element={<AsyncComponent importComponent={desligamento} />} />
          <Route exact path='/movimentacao' element={<AsyncComponent importComponent={movimentacao} />} />
          <Route exact path='/movimentacao/codcoligada/:codColigada/idReq/:idReq/reqTipo/:reqTipo' element={<AsyncComponent importComponent={movimentacao} />} />
          <Route exact path='/alteracaoEstrutura' element={<AsyncComponent importComponent={alteracaoEstrutura} />} />
          <Route exact path='/novaSecao' element={<AsyncComponent importComponent={novaSecaoPage} />} />
          <Route exact path='/alteracaoSecao' element={<AsyncComponent importComponent={alteracaoSecaoPage} />} />
          <Route exact path='/alocacaoColaborador' element={<AsyncComponent importComponent={alocacaoColaboradorPage} />} />
          <Route exact path='/inativarSecao' element={<AsyncComponent importComponent={inativarSecaoPage} />} />
          <Route exact path='/requestList/:type' element={<AsyncComponent importComponent={RequestListPage} />} />
          <Route exact path='/aprovacoes' element={<AsyncComponent importComponent={ListaAprovacaoPage} />} />
          <Route exact path='/organograma' element={<AsyncComponent importComponent={OrgPage} />} />
          <Route path='*' element={<Navigate to='/start' />} />
        </Routes>
      </App>
    </Suspense>
  </HashRouter>
);

export default AutRoutes;
