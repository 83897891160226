import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './menu.css';

const MenuItem = ({ item }) => {
  const { t } = useTranslation();
  return (
    <li id='menuItem' key={item.id} className='nav-item' >
      <NavLink to={item.href} className={'nav-link'} >
        <i className="material-icons" >{item.icon}</i>
        <p>{t(item.name)}</p>
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

export default MenuItem;
