import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import translatorService from '../../../services/translatorService';
import { PulseLoader } from 'react-spinners';
import './language.css';

const DgLanguage = props => {
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n  } = useTranslation();

  useEffect(() => {
    initLanguages();
  }, [props]);

  const initLanguages = () => {
    const languagesOptions = Object.keys(translatorService.resources)?.map(item => (item));
    const languagesList = languagesOptions.map(opt => ({ code: opt, name: translatorService.resources[opt].name }));
    setLanguages(languagesList);
    setIsLoading(false);
  };

  const selectLanguage = code => {
    i18n.changeLanguage(code);
    props.onHide();
  };

  const getButtons = () => {
    const buttons =
      <div className="col-md-12">
        <button onClick={props.onHide} className="btn btn-dark">{t('fechar')}</button>
      </div>;
    return buttons;
  };

  const getLanguages = () => {
    if (isLoading) {
      return <PulseLoader size={10} />;
    }
    
    let key = 1;
    const items = languages.map(lan => {
      const item = 
        <div className="row" key={key++}>
          <div className="col-sm-6 offset-4">
            <button className="forced-link" onClick={() => selectLanguage(lan.code)}>
              <img alt='flag' width={36} src={`../assets/img/flags/icon-${lan.code}-48.png`}></img>
              {lan.name}
            </button>
          </div>
        </div>;
      return item;
    });

    const component =
      <div>
        {items}
      </div>;
    return component;
  };

  const getContent = () => {
    var modal =
      <Modal show={props.show} onHide={props.onHide} size="md" aria-labelledby="contained-modal-title-lg" animation={false} >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">{t('selecioneIdioma')}</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          {getLanguages()}
        </Modal.Body>
        <Modal.Footer>
          {getButtons()}
        </Modal.Footer>
      </Modal>;
    return modal;
  };

  return (
    <Fragment>
      {getContent()}
    </Fragment>
  );
};

DgLanguage.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool
};

export default DgLanguage;
