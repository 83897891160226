import React from 'react';
import MenuItem from './MenuItem';
import PropTypes from 'prop-types';
import './menu.css';

const MenuWrapper = ({ menuItems, color }) => {
  const itemList = [];
  menuItems.forEach((item) => {
    itemList.push(<MenuItem key={item.id} item={item} color={color} />);
  });

  return (
    <div className="sidebar-wrapper">
      <ul className="nav">
        {itemList}
      </ul>
    </div>
  );
};

MenuWrapper.propTypes = {
  menuItems: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
};

export default MenuWrapper;
