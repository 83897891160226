import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from './menu/Menu';
import apiUtils from '../api/apiUtils';
import Header from './common/Header';
import Footer from './common/Footer';
import config from '../config';
import configAdmin from '../components/administration/config.admin';

const configMode = [{
  mode: 'default',
  servico: config
}, {
  mode: 'admin',
  servico: configAdmin
}];

const App = props => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    async function loadMenus() {
      setMenuItems(await getMenus());
    }
    
    if (!menuItems || !menuItems.length) {
      loadMenus();
    }
  }, [menuItems]);

  const getMenus = async () => {
    const appSettings = await apiUtils.getConfig();
    let menus = [...config.menuItems];
    if (!appSettings.orgChart || appSettings.orgChart === 'hide') {
      menus = menus.filter(obj => obj.name !== 'organograma');
    }
    return menus;
  };

  const config = configMode.find(rt => rt.mode === props.mode).servico;
  return (
    <div className="wrapper">
      <Menu items={menuItems} color={config.color} />
      <div className="main-panel">
        <Header />
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired
};

export default App;
