const config = {
  connectToApi: true,
  color: 'selectedMenu',
  administrationColor: 'adminMenu',

  menuItems: [{
    id: 1,
    active: true,
    href: '/parametro',
    icon: 'toggle_off',
    name: 'parametros',
    onlyWsRM: true
  }]
};

export default config;