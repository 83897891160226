import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const defaultSession = {
  requireValidation: false,
  logoff: false
};

export const sessionAtom = atom({ key: 'sessionAtom', default: { ...defaultSession }, effects_UNSTABLE: [persistAtom] });
