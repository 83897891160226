import React from 'react';
import Routes from './routes';
import AdministrationRoutes from './components/administration/AdministrationRoutes';
import Login from './components/login/LoginPage';
import clientServices from './services/clientServices';
import messages from './commons/constMessages';
import { useRecoilState } from 'recoil';
import { userAtom } from './atoms/userAtom';
import { useTranslation } from 'react-i18next';

const Start = () => {
  const [user] = useRecoilState(userAtom);
  const { t } = useTranslation();

  const getComponent = () => {
    let errorMessage = '';
    if (user.isAuth && !clientServices.persistEndpoint(user)) {
      errorMessage = t(messages.ErrMultiplasSecoes);
    } 

    const routes = !user.accessingAdministrationMode ? <Routes /> : <AdministrationRoutes />;
    const component = user.isAuth && !errorMessage ? routes : <Login errorMessage={errorMessage} />;
    return component;
  };

  return (getComponent());
};

export default Start;
