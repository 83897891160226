import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const defaultUser = {
  userName: '',
  password: '',
  name: '',
  isAuth: false,
  accessingAdministrationMode: false,
  accessInfo: {}
};

export const userAtom = atom({ key: 'userAtom', default: { ...defaultUser }, effects_UNSTABLE: [persistAtom] });
